<template>
  <!-- 编码:lzq -->
  <!-- 头部组件 -->
  <div>
    <main>
      <!-- 头部横幅区域 -->
      <div class="banner">
        <div>
          <img v-lazy="require('../assets/imgs/wisdom_scenic_spot/banner.webp')" alt="" />
        </div>
        <!-- 内容 -->
        <h2 class="title">智慧景区</h2>
        <p class="introduce">
          科学管理理论同现代信息技术高度集成，实现人与自然和谐发展的低碳智能运营景区
        </p>
      </div>
      <!-- 基础功能区域 -->
      <div class="basics">
        <div class="left_box">
          <div>
            <img v-lazy="
                require('../assets/imgs/wisdom_scenic_spot/marshalling9.png')
              " alt="" />
          </div>
          <h4>基础类功能</h4>
          <p>
            景区基础功能完备，交通指南、在线直播、VR体验、智能客服等大批优质服务，游客可随时体验，依托大数据、物联网、智能机器人等新技术，让游客尽情感受景区科技生活
          </p>
        </div>
        <div class="right_box">
          <div class="item_box">
            <h5>交通指南</h5>
            <span>
              展示前往景区最优公交、地铁、大巴、自驾等交通方式，满足游客从各个方向到景区的交通路线，进一步方便广大旅客更便捷、更舒适的出行
            </span>
            <p @click="jump('/Traffic_guidance')">了解详情 ></p>
          </div>
          <div class="item_box">
            <h5>文化活动</h5>
            <span>
              向景区游客展示城市的民俗，文化，戏曲等民间艺术文化活动，把城市深厚的文化底蕴展示出来，使游客都能感受到景区丰富的文化资源和浓厚的文化氛围
            </span>
            <p @click="jump('/cultural_activity')">了解详情 ></p>
          </div>
          <div class="item_box">
            <h5>行程规划</h5>
            <span>
              展示景区全局景点地图，优先显示景区内特色的景点，标出洗手间、停车场、服务中心等设施，按照不同特点列出游览路线，显示步行到达目的地的距离、所用时间等信息
            </span>
            <p @click="jump('/journey')">了解详情 ></p>
          </div>
          <div class="item_box">
            <h5>智能客服</h5>
            <span>
              24小时在线智能客服机器人，轻松应对多个用户客服请求，深度了解景区业务全况，轻松对接业务系统，自动完成信息查询、业务办理等任务，随时随地解决游客问题
            </span>
            <p @click="jump('/service')">了解详情 ></p>
          </div>
          <div class="item_box">
            <h5>游园详情</h5>
            <span>
              展示景区各景点详细介绍，历史故事、文化底蕴、人物事迹、纪念意义等，结合图片、视频形式展览、展播宣传。让游客充分认识景区独特的内涵
            </span>
            <p @click="jump('/play_page')">了解详情 ></p>
          </div>
          <div class="item_box">
            <h5>路线推荐</h5>
            <span>
              展示景区内全部景点，根据不同区域景点，向游客提供多个游览路线，自主选择、错峰游览，使游客最快捷浏览目的景点，提高景点游览率
            </span>
            <p @click="jump('/way_recommend')">了解详情 ></p>
          </div>
          <div class="bottom_box">
            <div class="item_bottom">
              <div class="image_box">
                <img v-lazy="
                    require('../assets/imgs/wisdom_scenic_spot/streaming.png')
                  " alt="" />
              </div>
              <div class="spn_box">
                <h4>在线直播</h4>
                <p @click="jump('/live_streaming')">了解详情 ></p>
              </div>
            </div>
            <div class="item_bottom">
              <div class="image_box">
                <img v-lazy="
                    require('../assets/imgs/wisdom_scenic_spot/experience.png')
                  " alt="" />
              </div>
              <div class="spn_box">
                <h4>VR体验</h4>
                <p @click="jump('/vr_films')">了解详情 ></p>
              </div>
            </div>
            <div class="item_bottom">
              <div class="image_box">
                <img v-lazy="
                    require('../assets/imgs/wisdom_scenic_spot/guide.png')
                  " alt="" />
              </div>
              <div class="spn_box">
                <h4>导游导览</h4>
                <p @click="jump('/The_guide_tour')">了解详情 ></p>
              </div>
            </div>
            <div class="item_bottom">
              <div class="image_box">
                <img v-lazy="require('../assets/imgs/wisdom_scenic_spot/cate.png')" alt="" />
              </div>
              <div class="spn_box">
                <h4>美食购物</h4>
                <p @click="jump('/foot_shopping')">了解详情 ></p>
              </div>
            </div>
            <div class="item_bottom">
              <div class="image_box">
                <img v-lazy="
                    require('../assets/imgs/wisdom_scenic_spot/shopping.png')
                  " alt="" />
              </div>
              <div class="spn_box">
                <h4>电子商城</h4>
                <p @click="jump('/e_mall')">了解详情 ></p>
              </div>
            </div>
            <div class="item_bottom">
              <div class="image_box">
                <img v-lazy="
                    require('../assets/imgs/wisdom_scenic_spot/AI_discern.png')
                  " alt="" />
              </div>
              <div class="spn_box">
                <h4>AI识景</h4>
                <p class="AI_none">敬请期待</p>
                <div class="images_none">
                  <img src="../assets/imgs/wisdom_scenic_spot/gand_3.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景区互联网与大数据应用模块 -->
      <div class="center_box">
        <div class="left_center">
          <div>
            <img v-lazy="
                require('../assets/imgs/wisdom_scenic_spot/marshalling8.png')
              " alt="" />
          </div>
          <h3>景区互联网与大数据应用</h3>
          <p>
            以计算机为大脑，依托万物互联，形成智慧系统，将景区旅游融入万物互联体系，依托云上思维，形成智慧数据网
          </p>
        </div>
        <div class="center_meddle">
          <h3>物联及算法</h3>
          <span>
            物联网边缘计算平台为您的IoT业务提供低延时、灵活、
            安全、便捷的边缘计算服务，节约了您的运维、开发、
            网络带宽等成本消耗
          </span>
          <div class="p_box">
            <p>人脸识别</p>
            <p>车辆识别</p>
            <p>轨迹跟踪</p>
            <p>行为特征</p>
          </div>
          <p class="detrail_p" @click="jump('/arithmetic')">了解详情 ></p>
        </div>
        <div class="right_center">
          <div class="right_item">
            <h2>智能联动</h2>
            <span>
              通过实时监控，将接入的各类信号进行综合分析及定型规划，第一时间获取信号，及时制定各种计划，达成联动效果
            </span>
            <p @click="jump('/linkage')">了解详情 ></p>
          </div>
          <div class="right_item">
            <h2>应急指挥</h2>
            <span>
              在突发情况下，精准定位GIS平台报警位置，信息化平台进行整合，最大范围内实现协同指挥调度
            </span>
            <p @click="jump('emergency_command')">了解详情 ></p>
          </div>
          <div class="right_item top_item">
            <h2>智慧景区大数据</h2>
            <span>
              智慧景区大数据提供了涵盖大数据基础设施和大数据应用在内的丰富产品及服务，助力客户快速构建企业级数据架构，获取数据时代核心竞争优势
            </span>
            <p @click="jump('/big_data')">了解详情 ></p>
          </div>
          <div class="right_item top_item">
            <h2>可视化大屏集中管控</h2>
            <span>
              把信息集中展示，使信息更灵活、更高效、更形象、更直观的可视化呈现
            </span>
            <p @click="jump('/visual')">了解详情 ></p>
          </div>
        </div>
      </div>
      <!-- 景区文化生活模块 -->
      <div class="end_box">
        <div class="live_left">
          <div>
            <img v-lazy="
                require('../assets/imgs/wisdom_scenic_spot/marshalling7.png')
              " alt="" />
          </div>
          <h3>景区文化生活</h3>
          <p>
            将景区烙上强烈的文化印记、融生态环境和人文生活于一体的景区文化生活
          </p>
        </div>
        <div class="live_right">
          <div class="live_list live_border">
            <h3>服务诱导</h3>
            <span>
              消费（优惠）券、免费乘车、探宝游戏等，让游客在游中使用平台和应用，帮助平台实现游客线上化，以达到提高服务质量，增强服务效果的目的
            </span>
            <p @click="jump('/Service_induced')">了解详情 ></p>
          </div>
          <div class="live_list live_border">
            <h3>游记自动生成</h3>
            <span>
              旅游达人养成，做自己生活的导演。智能游记小助手帮助游客时刻记录美好瞬间，根据游玩的景点自动记录，完成一天的旅行后一键游记自动生成
            </span>
            <p @click="jump('/Autogeneration_of_Travel_Notes')">了解详情 ></p>
          </div>
          <div class="live_list">
            <h3>硬件部署方案</h3>
            <span>
              景区物联网时代，运用信息和通信技术连接景区硬件，打造全方位景区智能云平台，实现景区智慧式管理和运行，促进景区的和谐、可持续成长
            </span>
            <p @click="jump('./hardware_deployment')">了解详情 ></p>
          </div>
          <div class="live_list">
            <h3>景区OTS</h3>
            <span>
              公共服务对接，模糊所谓线上线下的界限，以线上平台交易和营销思路，统一整个景区的所有营销单元，打造各类旅游品质服务信息交互的总平台
            </span>
            <p class="p_none">敬请期待</p>
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "wisdom_scenic_spot",
  components: {
    Header,
    Footer,
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>

<style lang="scss" scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .banner {
    width: 100%;
    height: 600px;
    position: relative;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // loading大小
      img[lazy='loading'] {
        width: 200px;
      }
      // 实际图片大小
      img[lazy='loaded'] {
        width: 100%;
        height: 600px;
      }
    }
  }

  @keyframes sport {
    0% {
      transform: translateY(80px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .title {
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    line-height: 67px;
    letter-spacing: 3px;
    top: 230px;
  }
  .introduce {
    width: 600px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    top: 321px;
  }
  .introduce,
  .title {
    user-select: none; //文本只读
    position: absolute;
    left: 360px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    animation: sport 0.5s;
    transform: translateY(0px);
  }
  // 基础功能区域
  .basics {
    display: flex;
    width: 1200px;
    height: 717px;
    margin: 80px auto;
    text-align: left;
    .left_box {
      position: relative;
      width: 300px;
      height: 100%;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        // loading大小
        img[lazy='loading'] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      h4 {
        position: absolute;
        top: 24px;
        left: 24px;
        margin-bottom: 16px;
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 1px;
      }
      p {
        position: absolute;
        top: 80px;
        left: 24px;
        right: 20px;
        height: 200px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
      }
    }
    .right_box {
      border-top: 1.5px solid #f4f4f4;
      display: flex;
      flex-wrap: wrap;
      width: 900px;
      height: 100%;
      .item_box {
        position: relative;
        border-right: 1.5px solid #f4f4f4;
        border-bottom: 1.5px solid #f4f4f4;
        padding: 23px 24px 0 24px;
        width: 300px;
        height: 260px;
        h5 {
          margin-bottom: 15px;
          height: 28px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
        span {
          display: block;
          height: 130px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        p {
          user-select: none; //文本只读
          cursor: pointer;
          position: absolute;
          bottom: 24px;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #007aff;
          line-height: 22px;
        }
      }
      .bottom_box {
        display: flex;
        flex-wrap: wrap;
        width: 900px;
        height: 196px;
        .item_bottom {
          position: relative;
          display: flex;
          align-items: center;
          width: 300px;
          height: 98px;
          border-bottom: 1.5px solid #f4f4f4;
          border-right: 1.5px solid #f4f4f4;
          .image_box {
            width: 56px;
            height: 56px;
            margin-left: 32px;
            margin-right: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .spn_box {
            margin-top: 10px;
            height: 60px;
            h4 {
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 6px;
            }
            p {
              user-select: none; //文本只读
              cursor: pointer;
              font-size: 16px;
              font-weight: 400;
              color: #007aff;
            }
            .AI_none {
              cursor: not-allowed;
              color: #cccccc;
            }
            .images_none {
              position: absolute;
              top: -3px;
              right: -3px;
              width: 56px;
              height: 56px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .center_box {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    height: 520px;
    text-align: left;
    margin: auto;
    margin-bottom: 80px;
    .left_center {
      position: relative;
      width: 300px;
      height: 100%;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy='loading'] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      h3 {
        position: absolute;
        top: 24px;
        left: 24px;
        right: 23px;
        width: 253px;
        height: 80px;
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        letter-spacing: 1px;
      }
      p {
        position: absolute;
        top: 120px;
        left: 24px;
        right: 23px;
        height: 200px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
      }
    }
    .center_meddle {
      position: relative;
      padding: 24px 23px 0 25px;
      border: 1.5px solid #f4f4f4;
      border-left: none;
      width: 300px;
      height: 100%;
      h3 {
        height: 28px;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-bottom: 15px;
      }
      span {
        display: block;
        height: 130px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
      .p_box {
        margin-top: 20px;
        p {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 22px;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #53e2c9;
            margin-right: 10px;
          }
        }
      }
      .detrail_p {
        user-select: none; //文本只读
        cursor: pointer;
        position: absolute;
        bottom: 24px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #007aff;
        line-height: 22px;
      }
    }
    .right_center {
      display: flex;
      flex-wrap: wrap;
      width: 600px;
      height: 100%;
      border-top: 1.5px solid #f4f4f4;
      border-bottom: 1.5px solid #f4f4f4;
      .right_item {
        position: relative;
        width: 300px;
        height: 260px;
        border-right: 1.5px solid #f4f4f4;
        padding: 24px 24px 0 22px;
        h2 {
          height: 28px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-bottom: 15px;
        }
        span {
          display: block;
          height: 130px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        p {
          user-select: none; //文本只读
          cursor: pointer;
          position: absolute;
          bottom: 24px;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #007aff;
          line-height: 22px;
        }
      }
      .top_item {
        border-top: 1.5px solid #f4f4f4;
      }
    }
  }
  .end_box {
    width: 1200px;
    height: 520px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 100px;
    text-align: left;
    .live_left {
      position: relative;
      width: 300px;
      height: 100%;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy='loading'] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      h3 {
        position: absolute;
        top: 24px;
        left: 24px;
        height: 40px;
        font-size: 28px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        letter-spacing: 1px;
      }
      p {
        position: absolute;
        top: 80px;
        left: 24px;
        right: 20px;
        height: 200px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
      }
    }
    .live_right {
      display: flex;
      flex-wrap: wrap;
      width: 900px;
      height: 100%;
      .live_list {
        position: relative;
        width: 50%;
        height: 260px;
        border-right: 1.5px solid #f4f4f4;
        border-bottom: 1.5px solid #f4f4f4;
        padding: 24px 23px 0 24px;
        h3 {
          height: 28px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-bottom: 15px;
        }
        span {
          display: block;
          height: 130px;
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }
        p {
          user-select: none; //文本只读
          cursor: pointer;
          position: absolute;
          bottom: 24px;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #007aff;
          line-height: 22px;
        }
        .p_none {
          cursor: not-allowed;
          color: #cccccc;
        }
      }
      .live_border {
        border-top: 1.5px solid #f4f4f4;
      }
    }
  }
</style>
